import { Flex, Heading } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { MDXRenderer } from "gatsby-plugin-mdx";
import React from "react";
import theme from "../@chakra-ui/gatsby-plugin/theme";
import { MarkdownContainer } from "./MarkdownContainer";

interface SectionHeaderProps {
  title: string;
  body: any;
}
export const SectionHeader = (props: SectionHeaderProps) => {
  const { title, body } = props;
  return (
    <Flex flexDirection="column">
      <Heading
        color="delta"
        mx="6"
        fontSize="2xl"
        fontWeight="semibold"
        as="h2"
        mt="8"
      >
        {title}
      </Heading>
      <SectionContainer mt="6">
        <MDXRenderer>{body}</MDXRenderer>
      </SectionContainer>
    </Flex>
  );
};

export const SectionContainer = styled(MarkdownContainer)`
  padding: 0;
  margin-left: ${theme.space[6]};
  margin-right: ${theme.space[6]};
  color: ${theme.colors.blackAlpha[700]};
  p {
    padding: 0;
  }
`;
