import { Flex } from "@chakra-ui/react";
import React from "react";
import { Header } from "../../components/Header";
import { HeaderBar } from "../../components/HeaderBar";
import { Layout } from "../../components/Layout";
import { Main } from "../../components/Main";
import { SectionDescription } from "../../features/share-plan/SectionDescription";
const IndexPage = ({ data }) => {
  return (
    <Layout>
      <Header>
        <HeaderBar />
      </Header>
      <Main pb="16" justifyContent="flex-start" alignItems="center">
        <Flex
          flexDirection="column"
          width="100%"
          maxWidth={["100%", null, null, "120ch"]}
        >
          <SectionDescription />
          <Flex mt="8">
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLSc84HWCVShErE4XgpWC8u_B8aYNvO6IU1weQnznjGvX0QSKow/viewform?embedded=true"
              width="100%"
              height="1100"
            >
              Loading…
            </iframe>
          </Flex>
        </Flex>
      </Main>
    </Layout>
  );
};

export default IndexPage;
